import styles from './Bio.module.scss';
import React from 'react';

class Bio extends React.Component {

	render() {
		return (
			<main className={styles.main}>
				<article>
					<h1>Maria Musngi</h1>
					<br></br>
					<h2 className={styles.bio_h2}>Artist Bio</h2>
					<hr></hr>
					<p>
						Maria Musngi graduated with a Bachelor of Fine Art from the University of
						Georgia. The artist lives in the beautiful mountains of Asheville, NC and her
						work is greatly inspired by the nature that surrounds her. She enjoys portraying
						the sky, the mountains and the water in either abstract or realistic forms. Her
						pieces contain several layers on canvas, which add depth and story to each painting.
						Maria often scratches through the many layers to reveal the history underneath
						each piece. Cold wax and the palette knife are used to further aid in the texture
						and nuance of each painting. This artist believes not only in the healing properties
						of nature, but of color as well. She paints with colors that she hopes give a sense
						of energy and well being to the viewer. During these unprecedented times, she
						hopes her paintings give some sense of hope, respite, and joy to those who see them.
					</p>
				</article>
			</main>
		)
	}
}

export default Bio;