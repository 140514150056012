import styles from './Home.module.scss';
import React from 'react';

class Home extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			imgNum: 1,
			imgName: '/assets/images/painting_01.jpg',
			numImgs: 11,
			nextPrevJustClicked: false,
		}

		this.sleep = this.sleep.bind(this);
		this.rotatePaintings = this.rotatePaintings.bind(this);
		this.nextImg = this.nextImg.bind(this);
		this.prevImg = this.prevImg.bind(this);
	}

	componentDidMount() {

		this.rotatePaintings();

			const nextBtn = document.querySelector('#nextImg');
			if (nextBtn !== null) {
				nextBtn.addEventListener('click', () => {
					this.nextImg();
					// this.state.nextPrevJustClicked = true;
					this.setState({nextPrevJustClicked : true});
				});
			}

			const prevBtn = document.querySelector('#prevImg');
			if (prevBtn !== null) {
				prevBtn.addEventListener('click', () => {
					this.prevImg();
					// this.state.nextPrevJustClicked = true;
					this.setState({nextPrevJustClicked : true});
				});
			}
	}

	sleep(millis) {
		return new Promise(resolve => setTimeout(resolve, millis));
	}

	async rotatePaintings() {
		while (true) {
			await this.sleep(5000);

			if (this.state.nextPrevJustClicked) {
				await this.sleep(5000);
				// this.state.nextPrevJustClicked = false;
				this.setState({nextPrevJustClicked : false});
			}

			this.nextImg();
		 }
	}

	nextImg() {
		if (this.state.imgNum < this.state.numImgs) {
			this.setState({ imgNum: this.state.imgNum + 1 });
		} else {
			this.setState({ imgNum:	1 });
		}

		if (this.state.imgNum < 10) {
		 this.setState({ imgName: "/assets/images/painting_0" + this.state.imgNum + ".jpg" });
		} else {
		 this.setState({ imgName: "/assets/images/painting_" + this.state.imgNum + ".jpg" });
		}
	}

	prevImg() {
		if (this.state.imgNum > 1) {
			this.setState({ imgNum: this.state.imgNum - 1 });
		} else {
			this.setState({ imgNum: this.state.numImgs });
		}

		if (this.state.imgNum < 10) {
		 this.setState({ imgName: "/assets/images/painting_0" + this.state.imgNum + ".jpg" });
		} else {
		 this.setState({ imgName: "/assets/images/painting_" + this.state.imgNum + ".jpg" });
		}
	}

	render() {
		return (
			<main className={styles.main}>
				<h1>Orb Fine Art</h1>
				<h2>Maria Musngi</h2>
				<section className={[styles.imageContainer, styles.imageOuterContainer].join(' ')}>
					<section className={[styles.imageContainer, styles.imageSubContainer].join(' ')}>
						<a className={[styles.nextPrev, styles.prev].join(' ')} id="prevImg">&#10094;</a>
						<figure className={styles.home_image}>
							<img src={this.state.imgName} alt="Painting Image" />
						</figure>
						<a className={[styles.nextPrev, styles.next].join(' ')} id="nextImg">&#10095;</a>
					</section>
				</section>
			</main>
		)
	}
}

export default Home;