import styles from './Gallery.module.scss';
import Swal from 'sweetalert2';
import React from 'react';

class Gallery extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			message: '',
		}

		this.setUpButtons = this.setUpButtons.bind(this);
		this.makeImgsClickable = this.makeImgsClickable.bind(this);
	}

	componentDidMount() {
		this.setUpButtons();
		this.makeImgsClickable();
	}

	setUpButtons() {
		const forSale = document.querySelectorAll('.buy');
		if (forSale != null) {
			for (let i = 0; i < forSale.length; i++) {
				const painting = forSale[i].parentElement?.previousElementSibling?.getAttribute('src');
				forSale[i].addEventListener('click', () => {
					Swal.fire({
						backdrop: true,
						background: 'rgba(17, 83, 92, 1.0)',
						confirmButtonColor: 'rgb(50, 110, 116)',
						title: '<h2>Site Under Construction</h2>',
						confirmButtonText: 'Ok',
						width: 500,
						position: 'center',
						heightAuto: false,
						padding: 0,
						imageUrl: String(painting),
						imageAlt: 'Painting image',
						imageHeight: 200,
						imageWidth: 'auto',
						html: "<h3 style='font-size:3vmin'>Please email me at <mark style='background-color:rgba(33, 100, 116, 0)'>" +
						"orbfineart@gmail.com</mark> to make a purchase. Option to purchase online coming soon!</h3>",
					});
				});
			}
		}
	}

	makeImgsClickable() {
		const imgs = document.querySelectorAll('.clickablePainting');
		if (imgs !== null) {
			for (let i = 0; i < imgs.length; i++) {
				const painting = imgs[i].getAttribute('src');
				imgs[i].addEventListener('click', () => {
				Swal.fire({
					backdrop: true,
					background: 'rgb(0, 0, 0)',
					confirmButtonColor: 'rgb(50, 110, 116)',
					title: '',
					confirmButtonText: 'Close',
					width: '88vmin',
					position: 'center',
					heightAuto: false,
					padding: 0,
					imageUrl: String(painting),
					imageAlt: 'Painting image',
					imageHeight: 'auto',
					imageWidth: 'auto',
				});
			})
		}
	}
	}

	render() {
		return (
			<main className={styles.main}>
				<h1>Gallery</h1>

				<section id={styles.gallery}>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_01.jpg" alt="Painting" />
					<div className='price'>
						<a className="buy">Buy</a>
					</div>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_02.jpg" alt="Painting" />
					<div className='price'>
						<a className="buy">Buy</a>
					</div>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_03.jpg" alt="Painting" />
					<mark>Sold</mark>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_04.jpg" alt="Painting" />
					<div className='price'>
						<a className="buy">Buy</a>
					</div>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_05.jpg" alt="Painting" />
					<mark>Sold</mark>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_06.jpg" alt="Painting" />
					<div className='price'>
						<a className="buy">Buy</a>
					</div>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_07.jpg" alt="Painting" />
					<div className='price'>
						<a className="buy">Buy</a>
					</div>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_08.jpg" alt="Painting" />
					<mark>Sold</mark>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_09.jpg" alt="Painting" />
					<mark>Sold</mark>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_10.jpg" alt="Painting" />
					<mark>Sold</mark>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_11.jpg" alt="Painting" />
					<mark>Sold</mark>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_12.jpg" alt="Painting" />
					<mark>Sold</mark>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_13.jpg" alt="Painting" />
					<mark>Sold</mark>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_14.jpg" alt="Painting" />
					<mark>Sold</mark>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_15.jpg" alt="Painting" />
					<mark>Sold</mark>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_16.jpg" alt="Painting" />
					<mark>Sold</mark>
				</div>
				<div className={styles.painting}>
					<img className="clickablePainting" src="/assets/images/painting_17.jpg" alt="Painting" />
					<mark>Sold</mark>
				</div>
			</section>
			</main>
		)
	}
}

export default Gallery;