import styles from './App.module.scss';
import Swal from 'sweetalert2';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './component/Home/Home';
import Bio from './component/Bio/Bio';
import Gallery from './component/Gallery/Gallery';

class App extends React.Component {

	render() {
		return (
			<BrowserRouter>
				<header>
					<div className="container">
						<nav>
							<ul>
								<li className={styles.home_bio} id="navHome"><a href="/" className={styles.navButton}>Home</a></li>
								<li className={styles.home_bio} id="navBio"><a href="/bio" className={styles.navButton}>Artist Bio</a></li>
								<li>
									<div className={styles.drop_div}>
										<button className={styles.hover_button}>Menu</button>
										<div className={styles.hover_drop_list}>
											<a id="navGallery" href="/gallery">Gallery</a>
											<a id="socialMedia">Social Media</a>
											<a id="contactInfo">Contact Info</a>
										</div>
									</div>
								</li>
							</ul>
						</nav>
					</div>
				</header>
				<Routes>
					<Route path='/' exact element={<Home />} />
					<Route path='/bio' exact element={<Bio />} />
					<Route path='/gallery' exact element={<Gallery />} />
				</Routes>
			</BrowserRouter>
		)
	}

	constructor(props) {
		super(props);
		this.state = {
			numImgs: 11,
			paintingNum: 1,
		}
		this.setUpButtons = this.setUpButtons.bind(this);
	}

	componentDidMount() {
		this.setUpButtons();
	}

	setUpButtons() {

		const socialMedBtn = document.querySelector('#socialMedia');
		if (socialMedBtn !== null) {
			socialMedBtn.addEventListener('click', () => {
				let painting = this.state.paintingNum < 10
											 ? './assets/images/painting_0' + this.state.paintingNum + '.jpg'
											 : './assets/images/painting_' + this.state.paintingNum + '.jpg';

				Swal.fire({
					backdrop: true,
					background: 'rgba(17, 83, 92, 1.0)',
					confirmButtonColor: 'rgb(50, 110, 116)',
					title: '',
					confirmButtonText: 'Ok',
					width: 650,
					position: 'center',
					heightAuto: false,
					padding: 0,
					imageUrl: painting,
					imageAlt: 'Painting',
					imageHeight: 200,
					imageWidth: 'auto',
					html: "<table><tr><th style='font-size:3vmin'>Instagram:</th>" +
					"<td style='color:rgb(33, 100, 116);font-size:3vmin'><a href='https://instagram.com/musngiart' target='new' rel='noopener " +
					"noreferrer'>instagram.com/musngiart</a></td></tr><tr><th style='font-size:3vmin'>" +
					"Facebook:</th><td style='color:rgb(33, 100, 116);" +
					"font-size:3vmin'><a href='https://facebook.com/musngiart' target='new' rel='noopener " +
					"noreferrer'>facebook.com/musngiart</a></td></tr></table>",
				});

				this.setState({ paintingNum: Math.floor(Math.random() * this.state.numImgs) + 1 });
			});
		}

		const contactBtn = document.querySelector('#contactInfo');
		if (contactBtn !== null) {
			contactBtn.addEventListener('click', () => {
				let painting = this.state.paintingNum < 10
											 ? './assets/images/painting_0' + this.state.paintingNum + '.jpg'
											 : './assets/images/painting_' + this.state.paintingNum + '.jpg';

				Swal.fire({
					backdrop: true,
					background: 'rgba(17, 83, 92, 1.0)',
					confirmButtonColor: 'rgb(50, 110, 116)',
					title: '',
					confirmButtonText: 'Ok',
					width: 500,
					position: 'center',
					heightAuto: false,
					padding: 0,
					imageUrl: painting,
					imageAlt: 'Painting',
					imageHeight: 200,
					imageWidth: 'auto',
					html: "<table><tr><th style='font-size:3vmin'>Email</th><tr><td><mark style='background-color:" +
					"rgba(33, 100, 116, 0);font-size:3vmin'>orbfineart@gmail.com</mark></td></tr></table>",
				});

				this.setState({ paintingNum: Math.floor(Math.random() * this.state.numImgs) + 1 });
			});
		}
	}
}


export default App;
